import posthog from "posthog-js";

import { env } from "~/env.mjs";
import { Analytics } from "./AnalyticsType";

if (typeof window !== "undefined" && env.NEXT_PUBLIC_POSTHOG_KEY) {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    capture_pageview: false,
    loaded: (posthog) => {
      // if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

export { posthog };

export class PosthogAnalytics implements Analytics {
  constructor() {}

  setUser(
    userId: string,
    userProperties?: { email?: string | null; username?: string | null },
  ) {
    posthog.identify(userId, userProperties);
  }

  trackEvent(
    eventInput: string,
    eventProperties: Record<string, any> | undefined,
  ) {
    posthog.capture(eventInput, eventProperties);
  }
}
