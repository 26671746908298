import { Analytics } from "./AnalyticsType";

export class LocalAnalytics implements Analytics {
  constructor() {
    console.log("Initialize local analytics");
  }

  setUser(
    id: string,
    userProperties?: { email?: string | null; username?: string | null },
  ) {
    console.log("Set user for analytics: ", id, userProperties);
  }

  trackEvent(
    eventInput: string,
    eventProperties?: Record<string, any> | undefined,
  ) {
    console.log("Track event: ", eventInput, eventProperties);
  }
}
