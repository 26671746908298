"use client";

import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { env } from "~/env.mjs";
import { useStore } from "~/hooks/store";
import firebaseApp, { fbAuth } from "~/lib/auth/firebase-app";
import { PHProvider } from "~/providers/posthog-provider";
import { AnalyticsContext } from "./AnalyticsContextState";
import { Analytics } from "./AnalyticsType";
import { LocalAnalytics } from "./LocalAnalytics";
import PostHogPageView from "./posthog-pageview";
import { PosthogAnalytics } from "./PosthogAnalytics";

export const analytics: Analytics = env.NEXT_PUBLIC_POSTHOG_KEY
  ? new PosthogAnalytics()
  : new LocalAnalytics();

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const loggedUser = useStore((s) => s.loggedUser);

  useEffect(() => {
    if (loggedUser) {
      analytics.setUser(loggedUser.uid, {
        email: loggedUser.email,
        username: loggedUser.displayName,
      });
    }
  }, [loggedUser]);

  return (
    <PHProvider>
      <PostHogPageView />
      <AnalyticsContext.Provider value={analytics}>
        {children}
      </AnalyticsContext.Provider>
    </PHProvider>
  );
};
